.review-request-container {
  width: 100%;
  margin: auto;
}

.review-request-container select {
  margin-bottom: 10px;
}

.review-form label {
  font-weight: 500;
}