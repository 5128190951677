.carrier-viewer-container {
  display: flex;
  width: 100%;
}

.workshop {
  padding: 25px;
  width: 650px;
  flex-shrink: 0;
  align-items: center;
}

.internal-notes {
  padding-bottom: 30px;
}

.summary {
  flex-grow: 1;
  min-width: 0;
  padding: 0px;
  margin: 0px;
}

.summary .document-container {
  max-width: none; /* Removes the 1100px limit */
  width: 100%; /* Ensures it expands fully */
}

.underwriting-notes-header {
  text-align: left;
  margin-bottom: 5px;
  font-size: 21px;
  color: #0c2441;
  border-bottom: 1px dashed #0c2441;
  padding: 3px;
  font-weight: 600;
}