.document-container {
  height: 91vh;
  margin: 0 auto;
  max-width: 1100px;
}

.error-message {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  margin-top: 20px;
  padding: 20px;
  border: 1px solid #d9534f;
  border-radius: 8px;
  width: 80%; /* Adjust based on your layout */
  max-width: 600px;
  margin: 20px auto; /* Centers the error message horizontally */
}
