.login-container {
  justify-content: center;
  height: 60vh;
}

.login-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.login-form-box {
  max-width: 22rem;
  width: 100%;
  padding: 1rem;
  border-radius: 0.25rem; /* equivalent to borderRadius: 1 */
  box-shadow: 0 4px 12px gray;
}

.typography-login-h2 {
  font-size: 2.2em;
  color: #000;
  margin-bottom: 8px; /* Assuming 1 spacing unit equals 8px */
}

.typography-login-h3 {
  font-size: 1.7em;
  text-decoration: underline;
  color: #0c2441;
  margin-bottom: 15px; /* Assuming 1 spacing unit equals 8px */
}

.typography-login-links {
  font-size: 0.9rem;
  margin-bottom: 0;
  padding: 2px 0 15px;
}

.loading-button {
  height: 45px;
  background-color: #34b0bf !important;
  letter-spacing: -.02em;
  font-size: 15px !important;
  text-decoration: none;
  font-weight: 600 !important;
  text-transform: none !important;
}

.loading-button:hover {
  transform: translateY(-2px);
}