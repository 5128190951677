.offer-detail-report-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding-top: 20px; /* Adjust as needed to create space at the top */
  max-width: 80%;
}

.offer-detail-logo-section {
  margin-bottom: 25px;
  display: flex;
  flex-direction: column; /* Arrange children vertically */
  align-items: center; /* Center horizontally */
  max-width: 800px;
}

.offer-detail-report-container  .applicant-header {
  background-color: #0c2441;
  color: #fff;
  font-family: 'Roboto', arial, sans-serif;
  font-weight: 400;
  font-size: 16pt;
  padding: 5px 0px 5px 15px;
  width: 100%; 
  border-radius: 10px 10px 0px 0px;
  border-bottom: 20px solid #34b0bf;
  box-shadow: 5px 0 9px rgba(0, 0, 0, 0.3), 0 5px 9px rgba(0, 0, 0, 0.3);
}

.applicant-header-last-name {
  font-family: 'Roboto', arial, sans-serif;
  font-weight: 900;
  font-size: 22pt;
}

/* Info Row (the row with 4 info boxes) */
.case-info-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 10px;
  padding: 15px 20px 30px 20px;
}

.case-info-item {
  text-align: left;
}

.case-info-value {
  color: #34b0bf;
  display: block;
  font-family: 'Roboto', arial, sans-serif;
  font-weight: 900;
  font-size: 18pt;
  height: 25px;
}

.case-info-label {
  color: #0c2441;
  font-size: 9pt;
  font-family: 'Roboto', arial, sans-serif;
  font-weight: 800;
}

/* Table of Offers */
.offers-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  box-shadow: 5px 0 9px rgba(0, 0, 0, 0.3), 0 5px 9px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.offers-table thead {
  background-color: #0c2441;
  color: #fff;
  text-align: left;
  font-family: 'Roboto', arial, sans-serif;
  font-weight: 400;
  font-size: 16pt;
  border-bottom: 20px solid #34b0bf;
}

.offers-table thead th:first-child {
  border-top-left-radius: 10px;
}

.offers-table thead th:last-child {
  border-top-right-radius: 10px;
}

/* Round the bottom corners of the last row */
.offers-table tbody tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}

.offers-table tbody tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}

.offers-table th {
  padding: 12px 16px;
  border-bottom: 20px solid #34b0bf;
  vertical-align: top;
}

.offers-table td {
  font-family: 'Roboto', arial, sans-serif;
  color: #0c2441;
  background-color: #E9E9EA;
  padding: 20px 16px;
  vertical-align: top;
}

.offers-table th {
  font-weight: normal;
}

.carrier-name {
  font-family: 'Roboto', arial, sans-serif;
  font-weight: 900;
  font-size: 13pt;
  height: 22px;
}

.offer-title {
  font-family: 'Roboto', arial, sans-serif;
  font-weight: 700;
  font-size: 13pt;
  height: 22px;
}

.offer-explanation {
  font-family: 'Roboto', arial, sans-serif;
  font-weight: 500;
  font-size: 13pt;
}

.sub-info {
  color: #0c2441;
  font-size: 9pt;
  font-family: 'Roboto', arial, sans-serif;
  font-weight: 800;
}

.offer-table-details-row td {
  padding: 4px 15px;  /* Reduce the padding */
  line-height: 1.2;  /* Reduce the line-height */
  vertical-align: top; /* Optional: align content to the top */
}

.offer-details-subject {
  color: #0c2441;
  background-color: #fff;
  border-radius: 10px;
  padding: 5px 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.offer-details-subject h6{
  font-size: 9pt;
  font-family: 'Roboto', arial, sans-serif;
  font-weight: 800;
  margin-bottom: 0px;
}

.details-subject {
  padding: 10px;
  font-family: 'Roboto', arial, sans-serif;
  font-weight: 500;
  font-size: 12pt;
}

@media print {
  .offer-detail-report-container {
    margin: 0 auto !important; /* Center horizontally */
    display: flex !important;
    justify-content: center !important;   /* Center horizontally for flex containers */
    width: 100% !important;              /* Ensure the container spans the page */
    box-sizing: border-box !important;   /* Include padding and border in dimensions */
    -webkit-print-color-adjust: exact; /* Ensures accurate color printing in WebKit browsers */
    print-color-adjust: exact; /* Ensures accurate color printing in other browsers */
  }
}
