.upload-container {
  padding-top: 20px;
}

.upload-form {
  width: 60%;
  margin: auto;
  padding-bottom: 25px;
}

.upload-form h3 {
  text-align: left;
  margin-bottom: 5px;
  font-size: 21px;
  color: #0c2441;
  border-bottom: 1px dashed #0c2441;
  padding: 3px;
}

.dropzone {
  border: 2px dashed #cccccc;
  border-radius: 8px;
  padding: 40px;
  text-align: center;
  cursor: pointer;
  margin-top: 20px;
  margin-bottom: 25px;
}

.how-to {
  margin-top: 25px;
  margin-bottom: 20px;
  padding: 10px 15px;
  border: 1px solid #34b0bf;
  border-radius: 4px;
  font-size: 11pt;
}

.how-to ol {
  padding-top: 10px;
  width: 100%;
  display: inline-block;
  text-align: left;
}

.radio-row {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.radio-row label {
  margin-top: 0px;
}

.radio-group {
  display: flex;
  align-items: center;
  margin-right: 20px; /* Adjust spacing as needed */
}

.upload-note {
  font-size: 11pt;
}

.dropzone.active {
  background-color: #f0f0f0; /* Optional: Add a highlight when active */
}

.file-list {
  margin-top: 10px;
}

.file-list ul {
  list-style-type: none;
  padding: 0;
}

.file-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f9f9f9;
  padding: 7px 12px;
  margin-bottom: 6px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  transition: background-color 0.3s;
}

.file-item:hover {
  background-color: #e8f0fe;
}

.file-name {
  font-size: 16px;
  color: #333;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* Delete Button Styles */
.delete-button {
  background: none;
  color: #ff4d4f;
  border: none;
  font-size: 18px;
  cursor: pointer;
  padding: 0 5px;
  transition: transform 0.2s, color 0.2s;
}

.delete-button:hover {
  color: #e02d2f;
  transform: scale(1.1);
}

.delete-button i {
  pointer-events: none; /* Ensures icon is non-interactive */
}


button[type="submit"] {
  display: block;
  width: 200px;
  height: 36px;
  margin: 20px auto;
  background-color: #34b0bf;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, opacity 0.3s;
}

button:disabled {
  background-color: #d3d3d3; 
  color: #a9a9a9; 
  opacity: 0.7; 
  cursor: not-allowed; 
}

.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.spinner {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin-bottom: 10px;
}

.strong {
  font-weight: 500;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
