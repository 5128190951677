.dashboard-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 25px;
}

.oq-exchange-partner-header { 
  display: none;
}

.report-logo-section, .report-logo-subsection {
  display: none;
  
}

.dashboard-filter {
  margin: auto;
  border: 1px solid #34b0bf;
  border-radius: 10px;
  padding: 10px 20px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.dashboard-filter label {
  margin-top: 0px;
  padding-top: 0px;
}

.filter-button {
  padding-top: 35px;
  width: 76px;
}

.header-table {
  width: 100%;
  min-width: 1030px;
}

.header-table th {
  /* Adjust the path to your actual SVG file */

  background: url("../../../assets/dashboardheader.png") no-repeat left center;
  background-size: contain; /* or 'cover' depending on desired effect */
  
  /* Make sure the cell is tall enough to show the design */
  vertical-align: middle;  /* center text vertically */
  text-align: center;
  color: #fff;
  width: 250px;
}

.header-table td {
  margin: 0;
  padding: 0;                /* optional, if you want to control padding precisely */
}

.header-table-last-name {
  font-family: 'Roboto', arial, sans-serif;
  font-weight: 900;
  font-size: 14pt;
}

.header-table-first-name {
  font-family: 'Roboto', arial, sans-serif;
  font-weight: 400;
  font-size: 14pt;
}

.header-table-td {
  color: #fff;
  background-color: #34b0bf;
  margin-top: 14px;
  margin-left: 0px;
  padding-top: 14px;
  padding-right: 5px;
  padding-left: 5px;
  height: 54px;
  font-family: 'Roboto', arial, sans-serif;
  font-size: 14pt;
}

.pipe-text {
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: 8px; /* space between M, the line, and FIRST LAST */
}

/* The pseudo-element acts as the vertical line */
.pipe-text::after {
  content: "";
  display: inline-block;
  width: 2px;
  height: 25px;
  background-color: #0d2e4a;
  margin-left: 2px;
  margin-right: 8px;
}

.offer-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  font-family: 'Roboto', arial, sans-serif;
  
}

.offer-table thead {
  text-align: center;
  margin-bottom: 10px;
  font-size: 11pt;
  font-weight: 500;
  height: 35px;
}

.offer-table td {
  font-size: 11pt;
  text-align: center;
}

.offer-table tbody {
  background-color: #E9E9EA;
}

.offer-table tbody tr:first-child td:first-child {
  border-top-left-radius: 12px;
}
.offer-table tbody tr:first-child td:last-child {
  border-top-right-radius: 12px;
}

/* Round bottom-left corner */
.offer-table tbody tr:last-child td:first-child {
  border-bottom-left-radius: 12px;
}

/* Round bottom-right corner */
.offer-table tbody tr:last-child td:last-child {
  border-bottom-right-radius: 12px;
}

.offer-table tbody tr {
  height: 40px;
}

.notes-container {
  display: flex;
  align-items: flex-start;
  gap: 10px; /* optional gap between the two sections */
}

.notes-toggle {
  padding-top: 5px;
  color: grey;
}

.notes-input {
  flex: 1; /* fills remaining space */
}

.notes-input textarea {
  height: 100px;
}

.notes-submit {
  display: flex;
  flex-direction: column;
}

.notes-submit label {
  margin-top: 0px;
}

.notes-submit select {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  background-color: #f7f9fd;
}

.notes-submit button {
  margin-top: 4px;
  margin-bottom: 0px;
}

.notes-section {
  background-color: #E9E9EA;
  border-radius: 12px;
  padding: 15px 15px 10px 15px;
}

