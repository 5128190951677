.hippa-upload-form {
  width: 100%;
}

.hippa-dropzone {
  border: 2px dashed #cccccc;
  border-radius: 8px;
  padding: 40px;
  text-align: center;
  cursor: pointer;
  margin-top: 20px;
}

.hippa-dropzone.active {
  background-color: #f0f0f0;
}
